/**
 * @author Yaochao
 * @date 2021/12/14 18:12
 * @description
 */
import React from 'react'
import Api from "../../util/Api";

class TaskPoster extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        let data = this.props.match.params.data;
        Api.getTaskRankPosterUrl(data, (resp) => {
            this.setState({
                posterUrl: resp.respMsg.url
            })
        })
    }

    render() {
        return (
            <div>
                <img src={this.state.posterUrl} style={{width:'80%',margin:'0 auto'}}/>
            </div>
        )
    }
}

export default TaskPoster