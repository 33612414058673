import React, {Component} from 'react';
import Loadable from 'react-loadable';
import {Route} from "react-router-dom";
import Loading from '../loading'

class Distri extends Component {
    render() {
        return (
            <div>
                <Route path={this.props.match.url + "/select"} component={
                    Loadable({
                        loader: () => import('./middlePage/select.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/success"} component={
                    Loadable({
                        loader: () => import('./middlePage/success.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/commit"} component={
                    Loadable({
                        loader: () => import('./middlePage/commitWX.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/selectWx/:id"} component={
                    Loadable({
                        loader: () => import('./middlePage/selectWX.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/word"} component={
                    Loadable({
                        loader: () => import('./middlePage/word.js'),
                        loading: Loading
                    })
                }/>

            </div>
        );
    }
}

export default Distri;