/**
 * @author Yaochao
 * @date 2021/12/21 17:41
 * @description
 */
import React from 'react'
import ImageTaskLevel1 from '../../image/taskrank/tasklevel1.png'
import ImageTaskLevel1Open from '../../image/taskrank/tasklevel1_open.png'
import ImageTaskLevel2 from '../../image/taskrank/tasklevel2.png'
import ImageTaskLevel2Open from '../../image/taskrank/tasklevel2_open.png'
import ImageTaskLevel3 from '../../image/taskrank/tasklevel3.png'
import ImageTaskLevel3Open from '../../image/taskrank/tasklevel3_open.png'
import Util from "../../util/util";

class Prizebox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            taskLevel: 1,
            completeLevel: 0,
        };
        this.BoxStyle = {};
    }

    componentDidMount() {
        this.setState({
            taskLevel: this.props.taskLevel,
            completeLevel: this.props.completeLevel
        });
        if (!Util.isNull(this.props.right)) {
            this.BoxStyle = {
                right: this.props.right
            };
        }
        if (!Util.isNull(this.props.left)) {
            this.BoxStyle = {
                left: this.props.left
            };
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            taskLevel: nextProps.taskLevel,
            completeLevel: nextProps.completeLevel
        });
        if (!Util.isNull(nextProps.right)) {
            this.BoxStyle = {
                right: nextProps.right
            };
        }
        if (!Util.isNull(nextProps.left)) {
            this.BoxStyle = {
                left: nextProps.left
            };
        }
    }

    getTaskLevelImage = () => {
        let taskLevel = this.state.taskLevel;
        let completeLevel = this.state.completeLevel;
        if (completeLevel == 2) {
            if (taskLevel == 1) {
                return ImageTaskLevel1Open
            }
            if (taskLevel == 2) {
                return ImageTaskLevel2Open;
            }
            if (taskLevel == 3) {
                return ImageTaskLevel3Open;
            }
        } else {
            if (taskLevel == 1) {
                return ImageTaskLevel1
            }
            if (taskLevel == 2) {
                return ImageTaskLevel2;
            }
            if (taskLevel == 3) {
                return ImageTaskLevel3;
            }
        }
    };

    prizeClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.state.taskLevel);
        }
    };

    render() {
        return (
            <div className='prizeBox' style={this.BoxStyle} onClick={this.prizeClick}>
                <label className={'boxDes' + this.state.completeLevel}>
                    {
                        this.state.completeLevel == 0 ? '未完成' : this.state.completeLevel == 1 ? '点击领奖' : this.state.completeLevel == 2 ? '已领奖' : '已完成'
                    }
                </label>
                <img src={this.getTaskLevelImage()}/>
            </div>
        )
    }
}

export default Prizebox