/**
 * @author Yaochao
 * @date 2021/12/15 14:52
 * @description
 */
import React from 'react'
import Api from "../../../util/Api";
import Midpage1 from "./midpage1";
import Midpage2 from "./midpage2";
import Midpage3 from "./midpage3";

class Midpage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: 0,
        }
    }

    componentDidMount() {
        let data = this.props.match.params.data;
        Api.getTaskMidPageData(data, (resp) => {
            this.setState({
                qrcode: resp.respMsg.qrcode,
                midtype: resp.respMsg.midtype,
                keyword: resp.respMsg.keyword,
                subsname: resp.respMsg.subsname,
                substype: resp.respMsg.substype,
                loaded: 1
            });
            document.title = '\u200E';
        })
    }

    render() {
        if (this.state.loaded) {
            return (
                <>
                    {
                        this.state.midtype == 0 ? <Midpage1 qrcode={this.state.qrcode} keyword={this.state.keyword}
                                                            substype={this.state.substype}/> : this.state.midtype == 1 ?
                            <Midpage2 qrcode={this.state.qrcode} keyword={this.state.keyword}
                                      substype={this.state.substype}/> :
                            <Midpage3 qrcode={this.state.qrcode} keyword={this.state.keyword}
                                      substype={this.state.substype}/>
                    }
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }
}

export default Midpage