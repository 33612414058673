/**
 * @author Yaochao
 * @date 2021/12/20 16:23
 * @description
 */
import React from 'react'
import Step from '../../../image/taskrank/midpage/step1.png'
import $ from "jquery";
import ChangAn from "../../../image/taskrank/midpage/mid_changan1.png";
import HuiFu from "../../../image/taskrank/midpage/mid_huifu1.png";

class Midpage1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        let init = this.init;
        window.onresize = function () {
            init();
        };
        this.init();
    }

    init() {
        let midWidth = 750;
        let tipFontSize = 22;
        let fantipFontSize = 26;
        let keywordFontSize = 32;
        let borderWidth = 8;
        let width = $(window).width();
        let height = $(window).height();
        let padTop = 0;
        if (width > 750) {
            width = 750
        }
        let rate = width / midWidth;
        let bw = borderWidth * rate;
        if (bw > 8) {
            bw = 8
        }

        let qrwidth = 264 * rate;
        let qrtop = 275 * rate;
        $("#qrcode").css({
            height: (qrwidth + qrtop) + "px",
        });

        $("#qrcode img").css({
            paddingTop: qrtop + padTop + "px",
            paddingLeft: (width - qrwidth) / 2 + "px",
            paddingRight: (width - qrwidth) / 2 + "px",
            width: qrwidth + 'px',
            height: qrwidth + "px",
        });

        $(".scan-tip").css("font-size", tipFontSize * rate + 'px');
        $(".oldfan-tip").css("font-size", fantipFontSize * rate + 'px');
        $(".keyword").css("font-size", keywordFontSize * rate + 'px');
    }

    render() {
        return (
            <div className="midpage1">
                <div className="middle">
                    <div className="qrcode" id="qrcode">
                        <img src={this.props.qrcode}/>
                    </div>
                    <div className='tipText1'>
                        <img src={ChangAn} className={'scan-tip midChangAn1'}/>
                        {
                            this.props.substype != 2 ? <div className="keyword">
                                <img src={HuiFu} className={'midHuiFu1'}/>
                                <br/>
                                「 <span id="keyword">{this.props.keyword}</span> 」
                            </div> : ''
                        }
                    </div>
                </div>
                <div className="step">
                    {/*<img src={Step}/>*/}
                </div>
                {/*<a href='https://www.94mxd.com.cn'>*/}
                    <div className="footer-logo"></div>
                {/*</a>*/}
                {/*<img className="bigQrcode" src={this.props.qrcode} alt=""/>*/}
            </div>
        )
    }
}

export default Midpage1