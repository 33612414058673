/**
 * @author Yaochao
 * @date 2021/12/14 18:06
 * @description
 */
import React from 'react'
import Prizebox from "./prizebox";
import RankProgress from "./progress";
import RankTab from "./ranktab";
import RankDetail from "./rank_detail";
import RankList from "./rank_list";
import RankSupport from "./rank_support";
import Api from "../../util/Api";
import ZkPopupModal from "../common/modal/zkPopupModal";
import Reward1 from '../../image/taskrank/reward1.png'
import Reward2 from '../../image/taskrank/reward2.png'
import Reward3 from '../../image/taskrank/reward3.png'
import ShareFriend from '../../image/taskrank/sharefriend.png'
import Clipboard from 'clipboard';
import {Toast} from "antd-mobile";

class TaskRank extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            headimgurl: '',
            nickname: '',
            currentRank: 0,
            taskLevel: 1,//任务阶梯
            currentSupport: 0,
            goal1: 0,
            goal2: 0,
            goal3: 0,
            progress: 0,
            detailSwitch: -1,
            completeLevel: [0, 0, 0],
            off: 0,
            nextLevel: 1,
            posterShow: false,
            isOver: false,
            newReward: false
        }
    }

    componentDidMount() {
        let data = this.props.match.params.data;
        Api.getTaskRankTaskInfo(data, (resp) => {
            let respMsg = resp.respMsg;
            this.setState({
                headimgurl: respMsg.headimgurl,
                nickname: respMsg.nickname,
                currentRank: respMsg.currentRank,
                taskLevel: respMsg.taskLevel,//任务阶梯
                currentSupport: respMsg.currentSupport,
                goal1: respMsg.goal1,
                goal2: respMsg.goal2,
                goal3: respMsg.goal3,
                detailSwitch: respMsg.detailSwitch,
                detail: respMsg.detail,
                id: respMsg.id,
                isOver: respMsg.isOver,
                newReward: respMsg.newReward,
                rewardContent: respMsg.rewardContent,
                completeLevel: respMsg.completeLevel,
            });
            this.initComplete(respMsg);
            if (!respMsg.isOver && respMsg.showPoster == 0) {
                this.showPoster();
            }
        });
        document.title = '人气排行榜';
        const copy = new Clipboard('.codeCopyBtn');
        copy.on('success', e => {
            Toast.info('兑换码复制成功', 1, null, false)
        });
    }

    initComplete = (data) => {
        let taskLevel = data.taskLevel;
        let goal1 = data.goal1;
        let goal2 = data.goal2;
        let goal3 = data.goal3;
        let currentSupport = data.currentSupport;
        let progress = 0;
        let off = 0, nextLevel = 1;
        if (taskLevel == 1) {
            //只有一阶任务
            if (currentSupport < goal1) {
                //未完成
                progress = currentSupport * 100.0 / goal1;
                off = goal1 - currentSupport;
            } else {
                //已完成
                progress = 100;
            }
        } else if (taskLevel == 2) {
            //有二阶任务
            let first = 42;
            if (currentSupport < goal1) {
                //二阶都未完成
                progress = (currentSupport * 1.0 / goal1) * first
                off = goal1 - currentSupport;
            } else {
                if (currentSupport >= goal1 && currentSupport < goal2) {
                    //只完成一阶
                    progress = first + ((currentSupport - goal1) * 1.0 / (goal2 - goal1)) * (100 - first)
                    off = goal2 - currentSupport;
                    nextLevel = 2
                } else if (currentSupport >= goal2) {
                    //二阶都完成
                    progress = 100;
                    nextLevel = 2
                }
            }
        } else if (taskLevel == 3) {
            //有三阶任务
            let first = 8, second = 44;
            if (currentSupport < goal1) {
                //三阶都未完成
                progress = (currentSupport * 1.0 / goal1) * first
                off = goal1 - currentSupport;
            } else {
                if (currentSupport >= goal1 && currentSupport < goal2) {
                    //只完成一阶
                    progress = first + ((currentSupport - goal1) * 1.0 / (goal2 - goal1)) * (second - first)
                    off = goal2 - currentSupport;
                    nextLevel = 2
                } else if (currentSupport >= goal2 && currentSupport < goal3) {
                    //完成一二阶都完成
                    progress = second + ((currentSupport - goal2) * 1.0 / (goal3 - goal2)) * (100 - second)
                    off = goal3 - currentSupport;
                    nextLevel = 3
                } else if (currentSupport >= goal3) {
                    //三阶都完成
                    progress = 100;
                    nextLevel = 3
                }
            }
        }
        this.setState({
            progress: progress,
            off: off,
            nextLevel: nextLevel
        })
    };

    getPoster = () => {
        let data = this.props.match.params.data;
        Api.getTaskRankPosterUrl(data, (resp) => {
            this.setState({
                posterUrl: resp.respMsg.url
            })
        })
    };

    showPoster = () => {
        this.setState({
            posterShow: true
        })
        this.getPoster();
    };

    closePoster = () => {
        this.setState({
            posterShow: false
        })
    };

    prizeClick = (level) => {
        let newReward = this.state.newReward;
        if (newReward) {
            //是新版
            let completeLevel = this.state.completeLevel;
            if (completeLevel[level - 1] > 0) {
                let data = this.props.match.params.data;
                Api.getTaskRankCompleteInfo(data, level, (resp) => {
                    if (resp.respCode == 0) {
                        let content = resp.respMsg.content;
                        let type = resp.respMsg.type;
                        if (type == 1) {
                            this.setState({
                                content: content
                            }, () => {
                                this.customModal.show();
                            })
                        } else if (type == 2 || type == 3 || type == 5) {
                            //商品链接，表单，自定义链接
                            window.location.href = content;
                        } else if (type == 4) {
                            this.setState({
                                redeemCode: content,
                                codeDesc: resp.respMsg.desc,
                                isLink: resp.respMsg.isLink,
                                linkText: resp.respMsg.linkText,
                            }, () => {
                                this.redeemModal.show();
                            })
                        }
                        if (type == 1 || type == 4 || type == 5) {
                            let completeLevel = this.state.completeLevel;
                            completeLevel[level - 1] = 2;
                            this.setState(completeLevel)
                        }
                    }
                })
            }
        }
    };

    receivePrize = (level) => {
        this.rewardModal.hide();
        this.prizeClick(level)
    };

    invite = () => {
        this.rewardModal.hide();
        this.showPoster();
    };

    getRedeemLinkText = () => {
        let redeemCode = this.state.redeemCode;
        let linkText = this.state.linkText;
        let link = '<a href="' + redeemCode + '">' + linkText + '</a>';
        return link;
    };

    getRewardImage = (item, index) => {
        if (item.goodsImgUrl) {
            return item.goodsImgUrl
        } else {
            if (index == 0) {
                return 'https://static.94mxd.com.cn/images/reward1.png'
            } else if (index == 1) {
                return 'https://static.94mxd.com.cn/images/reward2.png'
            } else if (index == 2) {
                return 'https://static.94mxd.com.cn/images/reward3.png'
            }
        }
    }

    render() {
        return (
            <div className='rankWraper'>
                <div className="fansRank">
                    <div className='fansRankWraper'>
                        <img className='fansHead'
                             src={this.state.headimgurl}/>
                        <div className='fansInfo'>
                            <p>{this.state.nickname}</p>
                            <label>
                                <span>排名&nbsp;&nbsp;</span>
                                <span>{this.state.currentRank > 0 ? this.state.currentRank : '未上榜'}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="inviteProgressWraper">
                    <div className='progressTitle'>
                        <p>邀请进度</p>
                        {
                            this.state.newReward ? <span onClick={() => {
                                this.rewardModal.show()
                            }}>领奖中心<div className='arrow'></div></span> : ''
                        }
                    </div>
                    <div className="inviteProgress">
                        {
                            this.state.taskLevel == 1 ? <div>
                                <RankProgress progress={this.state.progress}/>
                                <Prizebox taskLevel={1} completeLevel={this.state.completeLevel[0]} right={"2%"}
                                          onClick={this.prizeClick}/>
                            </div> : this.state.taskLevel == 2 ? <div>
                                    <RankProgress progress={this.state.progress}/>
                                    <Prizebox taskLevel={1} completeLevel={this.state.completeLevel[0]} left={'40%'}
                                              onClick={this.prizeClick}/>
                                    <Prizebox taskLevel={2} completeLevel={this.state.completeLevel[1]} right={"2%"}
                                              onClick={this.prizeClick}/>
                                </div> :
                                <div>
                                    <RankProgress progress={this.state.progress}/>
                                    <Prizebox taskLevel={1} completeLevel={this.state.completeLevel[0]} left={'6%'}
                                              onClick={this.prizeClick}/>
                                    <Prizebox taskLevel={2} completeLevel={this.state.completeLevel[1]} left={'42%'}
                                              onClick={this.prizeClick}/>
                                    <Prizebox taskLevel={3} completeLevel={this.state.completeLevel[2]} right={"2%"}
                                              onClick={this.prizeClick}/>
                                </div>
                        }
                    </div>
                    <p className='inviteDes'>已邀请&nbsp;<span
                        className='fontRed'><strong>{this.state.currentSupport}</strong>人</span>{this.state.off > 0 ?
                        <span>，还差&nbsp;<span
                            className='fontRed'><strong>{this.state.off}</strong>人完成任务</span></span> : ''}
                    </p>
                    {/*<p className='inviteDes'>已邀请&nbsp;<span*/}
                    {/*    className='fontRed'><strong>{this.state.currentSupport}</strong>人</span>，{this.state.off > 0 ?*/}
                    {/*    <span>还差&nbsp;<span*/}
                    {/*        className='fontRed'><strong>{this.state.off}</strong>人</span>&nbsp;即</span> : ''}可领取&nbsp;*/}
                    {/*    <span*/}
                    {/*        className='fontRed'>{this.state.nextLevel == 1 ? '一阶奖品' : this.state.nextLevel == 2 ? '二阶奖品' : '三阶奖品'}</span>*/}
                    {/*</p>*/}
                    {
                        this.state.isOver ? <a className='inviteBtn gray'>活动已结束</a> :
                            <a className='inviteBtn red' onClick={this.showPoster}>立即参与活动</a>
                    }
                </div>
                <div className="rankTabWraper">
                    {
                        this.state.detailSwitch != -1 ? (
                            this.state.detailSwitch ? <RankTab>
                                <RankDetail tabKey='1' tabName='活动详情' detail={this.state.detail}/>
                                <RankList tabKey='2' tabName='人气排行' params={this.props.match.params.data}/>
                                <RankSupport tabKey='3' tabName='助力好友' params={this.props.match.params.data}/>
                            </RankTab> : <RankTab>
                                <RankList tabKey='2' tabName='人气排行' params={this.props.match.params.data}/>
                                <RankSupport tabKey='3' tabName='助力好友' params={this.props.match.params.data}/>
                            </RankTab>
                        ) : ''
                    }
                </div>
                <div className={`${this.state.posterShow ? '' : 'mxd-display-none '}posterWraper`}
                     onClick={this.closePoster}>
                    <div className='poster'>
                        {/*<p><strong>长按保存海报，发给好友或分享朋友圈</strong></p>*/}
                        {/*<p>好友通过海报关注公众号即可助力</p>*/}
                        <img className='shareFriend' src={ShareFriend}/>
                        <img className='posterImg' src={this.state.posterUrl}/>
                        <div className='closePoster'></div>
                    </div>
                </div>
                <a href='https://www.94mxd.com.cn'>
                    <div className="footer-logo"></div>
                </a>
                <ZkPopupModal ref={r => this.customModal = r} className={'zkModal'} title={'领取奖品'}>
                    <div className='htmlModal'>
                        <div dangerouslySetInnerHTML={{__html: this.state.content}} className='ueditor'></div>
                    </div>
                    <div className='modalCloseBtn'>
                        <a onClick={() => {
                            this.customModal.hide()
                        }}>关闭</a>
                    </div>
                </ZkPopupModal>
                <ZkPopupModal ref={r => this.redeemModal = r} className={'zkModal'} title={'领取奖品'}>
                    <div className='htmlModal'>
                        <div>
                            <p className='codeTitle'>兑换码：</p>
                            {
                                this.state.isLink ? <div dangerouslySetInnerHTML={{__html: this.getRedeemLinkText()}}
                                                         className='codeContent'>

                                </div> : <div className='codeContent'>
                                    <span>{this.state.redeemCode}</span>
                                    <span data-clipboard-text={this.state.redeemCode}
                                          className="codeCopyBtn">复制</span>
                                </div>
                            }
                        </div>
                        {
                            this.state.codeDesc ? <div>
                                <p className='codeTitle'>兑换须知：</p>
                                <div className='codeDesc'>
                                    <div dangerouslySetInnerHTML={{__html: this.state.codeDesc}}
                                         className='ueditor'></div>
                                </div>
                            </div> : ''
                        }
                    </div>
                    <div className='modalCloseBtn'>
                        <a onClick={() => {
                            this.redeemModal.hide()
                        }}>关闭</a>
                    </div>
                </ZkPopupModal>
                <ZkPopupModal ref={r => this.rewardModal = r} className={'zkModal'}>
                    {
                        this.state.rewardContent ? <ul className='rewardCenter'>
                            {
                                this.state.rewardContent.map((item, index) => {
                                    return <li>
                                        <div className='rewardInfo'>
                                            <img src={this.getRewardImage(item, index)}/>
                                            <div className='rewardTitle'>
                                                <p>{item.title}</p>
                                                <span>累计邀请 <span
                                                    className='rewardGoal'>{index == 0 ? this.state.goal1 : index == 1 ? this.state.goal2 : this.state.goal3}</span> 人可领</span>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                this.state.completeLevel[index] == 0 ?
                                                    <a className='inviteBtn'
                                                       onClick={this.invite}>去邀请</a> : this.state.completeLevel[index] == 1 ?
                                                    <a className='receiveBtn'
                                                       onClick={this.receivePrize.bind(this, index + 1)}>去领取</a> :
                                                    <a className='receivedBtn'
                                                       onClick={this.receivePrize.bind(this, index + 1)}>已领取</a>
                                            }

                                        </div>
                                    </li>
                                })
                            }
                        </ul> : ''
                    }
                    <div className='modalCloseBtn'>
                        <a onClick={() => {
                            this.rewardModal.hide()
                        }}>关闭</a>
                    </div>
                </ZkPopupModal>
            </div>
        )
    }
}

export default TaskRank