import React, {Component} from 'react'
import {ActivityIndicator} from "antd-mobile"



class Loading extends Component {


    render() {
        return (
            <div>
                <div className="mxd-load">
                    <div className="loading-example">
                        <ActivityIndicator
                            text="Loading..."
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Loading