/**
 * @author Yaochao
 * @date 2021/12/21 19:19
 * @description
 */
import React from "react";
import Api from "../../util/Api";

class RankSupport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            supportList: []
        }
    }

    componentDidMount() {
        let params = this.props.params;
        Api.getTaskRankSupportList(params, (resp) => {
            this.setState({
                supportList: resp.respMsg
            })
        })
    }

    getSupportDes = (help, reason) => {
        if (help) {
            return '助力成功'
        } else {
            if (reason == 1) {
                return '已取关，助力无效'
            } else {
                return '老粉丝，助力无效'
            }
        }
        return '';
    }

    render() {
        return (
            <ul className='rankSupport'>
                <li className='rankTitle'>
                    <span>邀请好友</span>
                    <span>状态</span>
                </li>
                {
                    this.state.supportList.length > 0 ? this.state.supportList.map((item, index) => {
                        return <li>
                            <span>
                                {
                                    item.headimgurl ? <img src={item.headimgurl}/> : ''
                                }
                                <p>{item.nickname}</p>
                            </span>
                            <span>
                                <p>
                                    {
                                        this.getSupportDes(item.help, item.reason)
                                    }
                                </p>
                                <p>{new Date(item.supportTime).format('MM-dd hh:mm')}</p>
                            </span>
                        </li>
                    }) : <div className='noSupport'>还没有好友为你助力哦 ~</div>
                }
            </ul>
        );
    }
}

export default RankSupport