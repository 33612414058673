/**
 * @author Yaochao
 * @date 2021/12/21 19:19
 * @description
 */
import React from "react";

class RankDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detail: ''
        }
    }

    componentDidMount() {
        this.setState({
            detail: this.parserDetail(this.props.detail)
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            detail: this.parserDetail(nextProps.detail)
        })
    }

    parserDetail = (detail) => {
        detail = detail.replace("分享", '<img class="shareText" src="https://zk-cloud.oss-cn-beijing.aliyuncs.com/share_text.jpg"/>');
        detail = detail.replace("关注公众号", '<img class="shareText" src="https://zk-cloud.oss-cn-beijing.aliyuncs.com/scan_text.jpg"/>');
        return detail;
    };

    render() {
        return (
            <div className='ueditor' dangerouslySetInnerHTML={{__html: this.state.detail}}></div>
        );
    }
}

export default RankDetail