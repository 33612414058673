import React, {Component} from 'react'
import {Modal} from 'antd-mobile'

import Img1 from '../../../image/livecode/customer.jpeg'
import Util from "../../../util/util";

class Technology extends Component {
    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeService = this.closeService.bind(this);
        this.state = {}
    }

    componentDidMount() {
        this.getWord()
    }

    getWord() {
        let url = window.location.href
        if (url.indexOf("/livecode/") >= 0) {
            this.setState({
                tooltip: "点击免费创建活码",
                flag: "livecode",
                qrcode: Img1,
            })
        }
    }

    showModal() {
        this.setState({
            qrCodeFlag: true
        })
    }

    closeService() {
        this.setState({
            qrCodeFlag: false
        })
    }

    render() {
        let showSupport = this.props.showSupport;
        return (
            <div>
                {
                    showSupport == 1 ?
                        <div>
                            <div id="technology" className="technology enterTechnology">
                                <a href="https://www.94mxd.com.cn/home.html">
                                    <i className="icon-Technology"></i>
                                    <p className="technologyWord">提供技术支持</p>
                                </a>
                                {/*<p className="addCutom" onClick={this.showModal}>{this.state.tooltip}</p>*/}
                            </div>
                            <Modal
                                title={'联系客服创建活动'}
                                visible={this.state.qrCodeFlag}
                                transparent
                                onClose={this.closeService}
                                footer={[{text: "确定", onPress: this.closeService}]}
                            >

                                <div className="technologyCon">
                                    <img src={this.state.qrcode} className="serviceImg"/>
                                    <p className="serviceDesc">扫码添加客服微信</p>
                                </div>

                            </Modal>
                        </div> : ''
                }

            </div>
        )
    }
}

export default Technology








