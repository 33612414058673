import React, {Component} from 'react'
import {Route} from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "../loading";

class Logistics extends Component {
    render() {
        return (
            <div>
                <Route exact path={this.props.match.url + "/search/:id"} component={
                    Loadable({
                        loader: () => import('./search/search.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/result/:id"} component={
                    Loadable({
                        loader: () => import('./search/detail.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/batch/search/:id"} component={
                    Loadable({
                        loader: () => import('./search/batch_search.js'),
                        loading: Loading
                    })
                }/>
            </div>
        );
    }

}

export default Logistics;