import React, {Component} from 'react';
import Loadable from 'react-loadable';
import {Route} from "react-router-dom";
import Loading from '../loading'

class Form extends Component {
    render() {
        return (
            <div>
                <Route exact path={this.props.match.url + "/detail"} component={
                    Loadable({
                        loader: () => import('./middlePage/formMiddle.js'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/success"} component={
                    Loadable({
                        loader: () => import('./middlePage/success'),
                        loading: Loading
                    })
                }/>
            </div>
        );
    }
}

export default Form;