/**
 * @author Yaochao
 * @date 2021/12/20 17:21
 * @description
 */
import React from 'react'
import $ from 'jquery'
import Step from "../../../image/taskrank/midpage/step3.png";
import ChangAn from '../../../image/taskrank/midpage/mid_changan3.png'
import HuiFu from '../../../image/taskrank/midpage/mid_huifu3.png'

class Midpage3 extends React.Component {

    componentDidMount() {
        let init = this.init;
        window.onresize = function () {
            init();
        };
        this.init();
    }

    init() {
        let wH = $(window).height();
        let imgH = $('.pageWrapper').height();
        let imgW = $('.page').width();
        let top = imgW / 750 * 314;
        let padTop = -4;
        let qrW = 312 * imgW / 750;
        document.getElementsByClassName('qrcodeImg')[0].style.top = (top + padTop) + 'px';
        document.getElementsByClassName('qrcodeImg')[0].style.width = qrW + 'px';
        document.getElementsByClassName('qrcodeImg')[0].style.paddingLeft = (imgW - qrW) / 2 + 'px';
        document.getElementsByClassName('con')[0].style.paddingTop = (top + qrW + padTop) + 'px';
    }

    render() {
        return (
            <div className='midpage2 pageWrapper'>
                <div className="page" id="receiveImg">
                    <div className="con">
                        <div className="qrcode" id="qrcode"><img className="qrcodeImg" src={this.props.qrcode} alt=""/>
                        </div>
                        <div className="tipText">
                            <img src={ChangAn} className={'midChangAn3'}/>
                            <br/>
                            {
                                this.props.substype != 2 ? <p className="keyword">
                                    <img src={HuiFu} className={'midHuiFu3'}/>
                                    <span id="keyword">{this.props.keyword}</span>
                                </p> : ''
                            }
                        </div>
                        <div className="step">
                            {/*<img src={Step}/>*/}
                        </div>
                    </div>
                </div>
                {/*<img className="bigQrcode" src={this.props.qrcode} alt=""/>*/}
            </div>
        )
    }
}

export default Midpage3