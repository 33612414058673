/**
 * @author Yaochao
 * @date 2021/12/20 17:21
 * @description
 */
import React from 'react'
import Step from "../../../image/taskrank/midpage/step2.png";
import ChangAn from "../../../image/taskrank/midpage/mid_changan2.png";
import HuiFu from "../../../image/taskrank/midpage/mid_huifu2.png";

class Midpage2 extends React.Component {

    render() {
        return (
            <div className='midpage2'>
                <div className="wrapper bgBlue">
                    <div className="page2">
                        <div className="con">
                            <div className="qrcode" id="qrcode">
                                <img src={this.props.qrcode} alt=""/>
                                <span className="bgQrImg"></span>
                            </div>
                            <div className="tipText">
                                <img src={ChangAn} className={'midChangAn2'}/>
                                <br/>
                                {
                                    this.props.substype != 2 ? <p className="keyword">
                                        <img src={HuiFu} className={'midHuiFu2'}/>
                                        <span
                                        id="keyword">{this.props.keyword}</span>
                                    </p> : ''
                                }
                            </div>
                            <div className="step">
                                {/*<img src={Step}/>*/}
                            </div>
                        </div>
                    </div>
                    {/*<img className="bigQrcode" src={this.props.qrcode} alt=""/>*/}
                </div>
            </div>
        )
    }
}

export default Midpage2