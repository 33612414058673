import React from 'react'
import {withRouter} from "react-router-dom";
import Api from "../../util/Api";

class FestivalSign extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            coverUrl: null
        };
        this.getEventID = this.getEventID.bind(this);
    }

    componentDidMount() {
        Api.getFestivalSignCoverUrl(this.getEventID(), (msg) => {
            if (msg.respCode == 0) {
                this.setState({
                    coverUrl: msg.respMsg.url
                })
            }
        })
        document.title = '';
    }

    getEventID() {
        let eventID = this.props.match.params.eventID;
        return eventID;
    }

    gotoPoster() {
        this.props.history.push("/festivalsign/poster/" + this.getEventID());
    }

    render() {
        return (
            <div>
                <div className='festival-cover'>
                    {
                        this.state.coverUrl ? <img
                            src={this.state.coverUrl}/> : ''
                    }
                </div>
                <div className='festival-footer'>
                    <a onClick={this.gotoPoster.bind(this)}>立即查看</a>
                </div>
            </div>
        )
    }
}

export default withRouter(FestivalSign)