import {FetchUtil} from './FetchUtil'

const API_MXD = process.env.REACT_APP_API_MXD;

// const API_MXD = 'http://test.94mxd.com/mxd'

const URL = {
    liveCodeQunCode: API_MXD + "/livecode/qun/qrcode_info",
    liveCodeKfCode: API_MXD + "/livecode/kf/mid/qrcode_info",
    liveCodeQunCondeMid: API_MXD + "/livecode/qun/codeInfo_mid",//群活码中间页页面信息
    liveCodeQunJoinQun: API_MXD + "/livecode/qun/join_qun",
    liveCodeQunQrcodePress: API_MXD + "/livecode/qrcode_press",
    liveCodeQunTagList: API_MXD + '/livecode/qun/tag_list',
    liveCodeQrcodePress: API_MXD + '/livecode/qrcode_press',
    liveCodeAginjoinQun: API_MXD + '/livecode/qun/aginjoin_qun',
    liveCodeQunTagParam: API_MXD + '/livecode/qun/tag_param',
    helpVerify: API_MXD + '/taskposter/helpverify',


    formSubmit: API_MXD + '/form/submit',
    formGet: API_MXD + '/form/getfd',
    getForm: API_MXD + '/form/getform',
    formAppbrowse: API_MXD + '/form/appbrowse',
    getShareConfig: API_MXD + "/wechat/jsapi_sha1",
    domainOpenLog: API_MXD + '/domainmap/opened',
    festivalSignCoverUrl: API_MXD + '/festivalsign/activity_coverurl',
    festivalSignPosterUrl: API_MXD + '/festivalsign/activity_posterurl',

// 物流查询
    logisticsDataQuery: API_MXD + '/logistics/data/query',
    logisticsDataQueryAll: API_MXD + '/logistics/data/query_all',
    logisticsDataExists: API_MXD + '/logistics/data/exists',
    logisticsTitle: API_MXD + '/logistics/title',

    //任务宝
    getTaskRankposterUrl: API_MXD + '/taskposter/rank/poster/',
    getTaskMidPageDataUrl: API_MXD + '/taskposter/midpage/',
    getTaskRankTaskInfoUrl: API_MXD + '/taskposter/rank/taskinfo/',
    getTaskRankListUrl: API_MXD + '/taskposter/rank/list/',
    getTaskRankSupportListUrl: API_MXD + '/taskposter/rank/supportlist/',
    getTaskRankCompleteInfo: API_MXD + '/taskposter/rank/completeinfo',
};

class Api {

    static doGet(url, params, success, error) {
        let fetchUtil = new FetchUtil();

        fetchUtil.init()
            .setUrl(url)
            .setMethod('GET')
            .setCookieCors()
            .setBody(params)
            .dofetch()
            .then((data) => {
                if (success) {
                    success(data)
                }
            })
            .catch((errmsg) => {
                if (error) {
                    error(errmsg)
                }
            })
    }

    static doPost(url, bodyType, bodyData, success, error) {
        let fetchUtil = new FetchUtil();
        fetchUtil.init()
            .setUrl(url)
            .setMethod("POST")
            .setCookieCors()
            .setBodyType(bodyType)
            .setBody(bodyData ? bodyData : {})
            .dofetch()
            .then((data) => {
                if (success) {
                    success(data)
                }
            })
            .catch((errmsg) => {
                if (error) {
                    error(errmsg);
                }
            })
    }

    /**
     * 示例
     * @param success
     * @param error
     */

    // 活码中间页
    static getLiveCodeQunCode(str, param, success, error) {
        this.doGet(URL.liveCodeQunCode + '?' + str, param, success, error)
    }

    static getLiveCodeKfCode(str, param, success, error) {
        this.doGet(URL.liveCodeKfCode + '?' + str, param, success, error)
    }

    static getLiveCodeQunCondeMid(str, param, success, error) {
        this.doGet(URL.liveCodeQunCondeMid + '?' + str, param, success, error)
    }

    static getLiveCodeQunJoinQun(params, success, error) {
        this.doPost(URL.liveCodeQunJoinQun, 'json', params, success, error)
    }

    static getLiveCodeQunQrcodePress(params, success, error) {
        this.doPost(URL.liveCodeQunQrcodePress, 'json', params, success, error)
    }


    static getliveCodeQunTagList(str, param, success, error) {
        this.doGet(URL.liveCodeQunTagList + '?' + str, param, success, error)
    }

    static liveCodeQrcodePress(params, success, error) {
        this.doPost(URL.liveCodeQrcodePress, 'json', params, success, error)
    }

    static getLiveCodeAginjoinQun(params, success, error) {
        this.doPost(URL.liveCodeAginjoinQun, 'json', params, success, error)
    }

    static getLiveCodeQunTagParam(str, param, success, error) {
        this.doGet(URL.liveCodeQunTagParam + '?' + str, param, success, error)
    }

    static formGet(id, success, error) {
        this.doGet(URL.formGet + '?fd=' + id, {}, success, error)
    }

    static getForm(id, success, error) {
        this.doPost(URL.getForm, "json", {fd: id}, success, error)
    }

    static formAppbrowse(fd, success, error) {
        this.doPost(URL.formAppbrowse + "?key=" + fd, "json", {}, success, error)
    }

    static getShareConfig(param, success, error) {
        this.doPost(URL.getShareConfig, 'json', {url: param}, success, error)
    }

    static formSubmit(param, success, error) {
        this.doPost(URL.formSubmit, 'json', param, success, error)
    }

    /**
     * 助力验证接口
     * @param eventID
     * @param success
     * @param error
     */
    static helpVerify(eventID, success, error) {
        this.doPost(URL.helpVerify + '?eventID=' + eventID, "json", {}, success, error);
    }

    /**
     * 域名点击
     * @param dlogid
     * @param success
     * @param error
     */
    static domainOpenLog(dlogid, success, error) {
        this.doGet(URL.domainOpenLog + '?dlogid=' + dlogid, {}, success, error)
    }

    /**
     * 获取节日签封面
     * @param eventID
     * @param success
     * @param error
     */
    static getFestivalSignCoverUrl(eventID, success, error) {
        this.doGet(URL.festivalSignCoverUrl + '?eventID=' + eventID, {}, success, error)
    }

    /**
     * 获取节日签海报
     * @param eventID
     * @param success
     * @param error
     */
    static getFestivalSignPosterUrl(eventID, success, error) {
        this.doGet(URL.festivalSignPosterUrl + '?eventID=' + eventID, {}, success, error)
    }

    static logisticsDataQuery(params, success, error) {
        let url = URL.logisticsDataQuery;
        this.doPost(url, 'json', params, success, error);
    }

    static logisticsDataQueryAll(params, success, error) {
        let url = URL.logisticsDataQueryAll;
        this.doPost(url, 'json', params, success, error);
    }

    static logisticsDataExists(params, success, error) {
        this.doPost(URL.logisticsDataExists, "json", params, success, error);
    }

    static logisticsTitle(str, success, error) {
        let url = URL.logisticsTitle;
        this.doGet(url + '?' + str, success, error);
    }

    /**
     * 获取任务海报
     * @param data
     * @param success
     * @param error
     */
    static getTaskRankPosterUrl(data, success, error) {
        let url = URL.getTaskRankposterUrl + data;
        this.doGet(url, {}, success, error)
    }

    /**
     * 获取中间页数据
     * @param data
     * @param success
     * @param error
     */
    static getTaskMidPageData(data, success, error) {
        let url = URL.getTaskMidPageDataUrl + data;
        this.doGet(url, {}, success, error)
    }

    /**
     * 获取排行榜任务信息
     * @param data
     * @param success
     * @param error
     */
    static getTaskRankTaskInfo(data, success, error) {
        let url = URL.getTaskRankTaskInfoUrl + data;
        this.doGet(url, {}, success, error)
    }

    /**
     * 获取排行榜列表
     * @param data
     * @param success
     * @param error
     */
    static getTaskRankList(data, success, error) {
        let url = URL.getTaskRankListUrl + data;
        this.doGet(url, {}, success, error)
    }

    /**
     * 获取好友列表
     * @param data
     * @param success
     * @param error
     */
    static getTaskRankSupportList(data, success, error) {
        let url = URL.getTaskRankSupportListUrl + data;
        this.doGet(url, {}, success, error)
    }

    /**
     * 获取任务完成信息
     * @param data
     * @param level
     * @param success
     * @param error
     */
    static getTaskRankCompleteInfo(data, level, success, error) {
        let url = URL.getTaskRankCompleteInfo + '?data=' + data + '&level=' + level;
        this.doGet(url, {}, success, error)
    }
}

export default Api

