/**
 * @author Yaochao
 * @date 2021/12/21 19:08
 * @description
 */
import React from 'react'

class RankTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: null
        }
    }

    componentDidMount() {
        let activeKey = this.state.activeKey;
        if (activeKey == null) {
            let keys = this.getChildrenKeys();
            if (keys.length > 0) {
                this.setState({
                    activeKey: keys[0].key
                })
            }
        }
    }

    getChildren = () => {
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, this.props);
        })
    };

    getChildrenKeys = () => {
        return React.Children.map(this.props.children, child => {
            return {key: child.props.tabKey, name: child.props.tabName};
        })
    };

    tabClick = (key) => {
        this.setState({
            activeKey: key
        });
    };

    render() {
        return (
            <div className='rankTab'>
                <ul className='rankTabList'>
                    {
                        this.getChildrenKeys().map((item) => {
                            return <li className={this.state.activeKey == item.key ? 'active' : ''} key={item.key}
                                       onClick={this.tabClick.bind(this, item.key)}>{item.name}</li>
                        })
                    }
                </ul>
                {
                    this.getChildren().map((child, id) => {
                        return <div
                            key={child.props.tabKey}>{this.state.activeKey == child.props.tabKey ? child : ''}</div>
                    })
                }
            </div>
        )
    }
}

export default RankTab