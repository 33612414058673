/**
 * @author Yaochao
 * @date 2021/12/21 19:19
 * @description
 */
import React from "react";
import Api from "../../util/Api";
import Rank1 from '../../image/taskrank/rank1.svg'
import Rank2 from '../../image/taskrank/rank2.svg'
import Rank3 from '../../image/taskrank/rank3.svg'

const PageNum = 10, PageSize = 20;

class RankList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rankList: [],
            pageNum: 1
        }
    }

    componentDidMount() {
        let data = this.props.params;
        Api.getTaskRankList(data, (resp) => {
            let list = resp.respMsg;
            this.setState({
                rankList: list
            });
        })
    }

    getRankList = () => {
        let pageNum = this.state.pageNum;
        let rankList = this.state.rankList;
        return rankList.filter((item, index) => {
            if (index < (pageNum * PageSize)) {
                return item;
            }
        })
    };

    isShowMore = () => {
        let total = this.state.pageNum * PageSize;
        let rankList = this.state.rankList;
        if (this.state.pageNum < PageNum && rankList.length > total) {
            return true;
        }
        return false;
    };

    showMore = () => {
        let pageNum = this.state.pageNum;
        if (pageNum < PageNum) {
            pageNum++;
            this.setState({
                pageNum: pageNum
            })
        }
    };

    render() {
        let pageNum = this.state.pageNum;
        return (
            <ul className='rankList'>
                <li className='rankTitle'>
                    <span>排名</span>
                    <span>用户信息</span>
                    <span>邀请</span>
                </li>
                {
                    this.getRankList().map((item, index) => {
                        return <li>
                            <span>{index > 2 ? <label>{(index + 1)}</label> :
                                <img src={index == 0 ? Rank1 : index == 1 ? Rank2 : Rank3}/>}</span>
                            <span>
                               {
                                   item.headimgurl ? <img src={item.headimgurl}/> : ''
                               }
                                <p>{item.nickname}</p>
                            </span>
                            <span>{item.support}
                                <e>人</e></span>
                        </li>
                    })
                }
                {
                    this.isShowMore() ? <div className='showMore' onClick={this.showMore}>加载更多 >></div> : ''
                }
                {
                    this.state.rankList.length >= PageNum * PageSize && this.state.pageNum == PageNum ?
                        <div className='max50'>— 最多显示前200名哦 —</div> : ''
                }
            </ul>
        );
    }
}

export default RankList