import React from 'react'
import Api from "../../util/Api";
import {withRouter} from "react-router-dom";

class FestivalSignPoster extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            posterUrl: null
        };
        this.getEventID = this.getEventID.bind(this);
    }

    componentDidMount() {
        Api.getFestivalSignPosterUrl(this.getEventID(), (msg) => {
            if (msg.respCode == 0) {
                this.setState({
                    posterUrl: msg.respMsg.url
                })
            }
        })
        document.title = '';
    }

    getEventID() {
        let eventID = this.props.match.params.eventID;
        return eventID;
    }

    render() {
        if (this.state.posterUrl) {
            return (
                <div>
                    <p className='festival-poster-header'>
                        长按图片保存到相册，快去分享到朋友圈吧~
                    </p>
                    <div className='festival-poster'>
                        <img
                            src={this.state.posterUrl}/>
                    </div>
                </div>
            )
        } else {
            return <div className='festival-loading'>
                <p>海报生成中，请耐心等待...</p>
            </div>
        }

    }
}

export default withRouter(FestivalSignPoster)