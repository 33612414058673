import React, {Component} from 'react'

import QunClose from '../../../image/groupbuy/qunClose.png';
import Technology from "../../commen/technology/technology";
import Util from "../../../util/util";

class Template extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSupport: 1
        }
    }

    componentDidMount() {
        let showSupport = Util.getUrlArg('showSupport');
        this.setState({
            showSupport: showSupport
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    render() {
        return (
            <div>
                <div className="qunAll">
                    <img src={QunClose} alt="" className="qunCloseImg"/>
                    <span className="qunAllWord">
                        扫码通道已关闭</span>
                </div>


                <Technology showSupport={this.state.showSupport}></Technology>

            </div>
        )
    }
}

export default Template








