import React from 'react'
import Util from "../../util/util";
import Api from "../../util/Api";
import {Modal} from "antd-mobile";
import Technology from "../commen/technology/technology";

class HelpVerify extends React.Component {

    constructor(props) {
        super(props);
        this.verifySuccess = this.verifySuccess.bind(this);
        this.onClose = this.onClose.bind(this);
        this.showModal = this.showModal.bind(this);
        this.state = {
            showModal: false,
            modalTitle: ''
        }
    }

    componentDidMount() {
        if (Util.isMobile()) {
            this.initMobile();
        } else {
            this.initPC();
        }
        document.title = '好友助力验证';
    }

    initMobile() {
        let verifySuccess = this.verifySuccess;
        //获取滑动控件容器,灰色背景
        let dragContainer = document.getElementById("dragContainer");
        //获取滑块左边部分,绿色背景
        let dragBg = document.getElementById("dragBg");
        //获取滑动验证容器文本
        let dragText = document.getElementById("dragText");
        //获取滑块
        let dragHandler = document.getElementById("dragHandler");

        //滑块的最大偏移量                 =     滑动验证容器文本长度                  -      滑块长度
        let maxHandlerOffset = dragContainer.clientWidth - dragHandler.clientWidth;
        //是否验证成功的标记
        let isVertifySucc = false;
        let x = 0;

        initDrag();

        function initDrag() {
            //在滑动验证容器文本写入“拖动滑块验证”
            dragText.textContent = "拖动滑块验证";
            //给滑块添加鼠标按下监听
            dragHandler.addEventListener("touchstart", onDragHandlerTouchStart);
        }

        //选中滑块
        function onDragHandlerTouchStart(event) {
            x = event.changedTouches[0].clientX;
            //鼠标移动监听
            dragHandler.addEventListener("touchmove", onDragHandlerTouchMove);
            //鼠标松开监听
            dragHandler.addEventListener("touchend", onDragHandlerTouchEnd);
        }

        //滑块移动
        function onDragHandlerTouchMove(event) {
            /*
            html元素不存在width属性,只有clientWidth
            offsetX是相对当前元素的,clientX和pageX是相对其父元素的
            */
            //滑块移动量
            let left = event.changedTouches[0].clientX - x - dragHandler.clientWidth / 2;
            //
            if (left < 0) {
                left = 0;
                //如果滑块移动量   > 滑块的最大偏移量 ，则调用验证成功函数
            } else if (left > maxHandlerOffset) {
                left = maxHandlerOffset;
                verifySucc();
            }
            //滑块移动量
            dragHandler.style.left = left + "px";
            //绿色背景的长度
            dragBg.style.width = dragHandler.style.left;
        }

        //松开滑块函数
        function onDragHandlerTouchEnd() {
            //移除鼠标移动监听
            dragHandler.removeEventListener("touchmove", onDragHandlerTouchMove);
            //移除鼠标松开监听
            dragHandler.removeEventListener("touchend", onDragHandlerTouchEnd);
            //初始化滑块移动量
            dragHandler.style.left = 0;
            //初始化绿色背景
            dragBg.style.width = 0;
        }

        //验证成功
        function verifySucc() {
            //成功标记，不可回弹
            isVertifySucc = false;
            //容器文本的文字改为白色“验证通过”字体
            dragText.textContent = "验证通过";
            dragText.style.color = "white";
            //验证通过的滑块背景
            dragHandler.setAttribute("class", "dragHandlerOkBg");
            //移除鼠标按下监听
            dragHandler.removeEventListener("touchstart", onDragHandlerTouchStart);
            //移除 鼠标移动监听
            dragHandler.removeEventListener("touchmove", onDragHandlerTouchMove);
            //移除鼠标松开监听
            dragHandler.removeEventListener("touchend", onDragHandlerTouchEnd);
            // 匹配成功以后写入你要跳转的页面
            // window.location.href = "成功页面.html";
            verifySuccess();
        };
    }

    initPC() {
        let verifySuccess = this.verifySuccess;
        //获取滑动控件容器,灰色背景
        let dragContainer = document.getElementById("dragContainer");
        //获取滑块左边部分,绿色背景
        let dragBg = document.getElementById("dragBg");
        //获取滑动验证容器文本
        let dragText = document.getElementById("dragText");
        //获取滑块
        let dragHandler = document.getElementById("dragHandler");

        //滑块的最大偏移量                 =     滑动验证容器文本长度                  -      滑块长度
        let maxHandlerOffset = dragContainer.clientWidth - dragHandler.clientWidth;
        //是否验证成功的标记
        let isVertifySucc = false;
        let x = 0;

        initDrag();

        function initDrag() {
            //在滑动验证容器文本写入“拖动滑块验证”
            dragText.textContent = "拖动滑块验证";
            //给滑块添加鼠标按下监听
            dragHandler.addEventListener("mousedown", onDragHandlerMouseDown);
        }

        //选中滑块
        function onDragHandlerMouseDown(event) {
            x = event.clientX;
            //鼠标移动监听
            document.addEventListener("mousemove", onDragHandlerMouseMove);
            //鼠标松开监听
            document.addEventListener("mouseup", onDragHandlerMouseUp);
        }

        //滑块移动
        function onDragHandlerMouseMove(event) {
            /*
            html元素不存在width属性,只有clientWidth
            offsetX是相对当前元素的,clientX和pageX是相对其父元素的
            */
            //滑块移动量
            var left = event.clientX - x - dragHandler.clientWidth / 2;
            //
            if (left < 0) {
                left = 0;
                //如果滑块移动量   > 滑块的最大偏移量 ，则调用验证成功函数
            } else if (left > maxHandlerOffset) {
                left = maxHandlerOffset;
                verifySucc();
            }
            //滑块移动量
            dragHandler.style.left = left + "px";
            //绿色背景的长度
            dragBg.style.width = dragHandler.style.left;
        }

        //松开滑块函数
        function onDragHandlerMouseUp() {
            //移除鼠标移动监听
            document.removeEventListener("mousemove", onDragHandlerMouseMove);
            //移除鼠标松开监听
            document.removeEventListener("mouseup", onDragHandlerMouseUp);
            //初始化滑块移动量
            dragHandler.style.left = 0;
            //初始化绿色背景
            dragBg.style.width = 0;
        }

        //验证成功
        function verifySucc() {
            //成功标记，不可回弹
            isVertifySucc = false;
            //容器文本的文字改为白色“验证通过”字体
            dragText.textContent = "验证通过";
            dragText.style.color = "white";
            //验证通过的滑块背景
            dragHandler.setAttribute("class", "dragHandlerOkBg");
            //移除鼠标按下监听
            dragHandler.removeEventListener("mousedown", onDragHandlerMouseDown);
            //移除 鼠标移动监听
            document.removeEventListener("mousemove", onDragHandlerMouseMove);
            //移除鼠标松开监听
            document.removeEventListener("mouseup", onDragHandlerMouseUp);
            // 匹配成功以后写入你要跳转的页面
            // window.location.href = "成功页面.html";
            verifySuccess();
        };
    }

    verifySuccess() {
        let eventID = this.props.match.params.eventID;
        let showModal = this.showModal;
        Api.helpVerify(eventID, function (data) {
            if (data.respCode == 0) {
                showModal('恭喜！您已帮好友助力成功');
            } else if (data.respCode == 1092) {
                showModal('活动已结束，您已无法帮好友助力');
            } else {
                showModal('助力失败');
            }
        }, function (error) {
            showModal('助力失败');
        })
    }

    onClose() {
        this.setState({
            showModal: false
        })
        if (window.WeixinJSBridge) {
            window.WeixinJSBridge.call('closeWindow');
        }
    }

    showModal(title) {
        this.setState({
            showModal: true,
            modalTitle: title
        })
    }

    render() {
        return (
            <div className="verify">
                <div className="verifyHead">
                    <label>好友助力验证</label>
                </div>
                <div className="verifyContent">
                    <label>拖动滑块至末端，完成验证，方可成功助力</label>
                    <div id="dragContainer">
                        <div id="dragBg"></div>
                        <div id="dragText"></div>
                        <div id="dragHandler" className="dragHandlerBg"></div>
                    </div>
                </div>
                <Modal
                    visible={this.state.showModal}
                    transparent
                    maskClosable={false}
                    onClose={this.onClose}
                    // title={this.state.modalTitle}
                    footer={[{
                        text: '关闭', onPress: () => {
                            this.onClose();
                        }
                    }]}
                >
                    <div>
                        {this.state.modalTitle}
                    </div>
                </Modal>
                <Technology showSupport={true}></Technology>
            </div>
        )
    }
}

export default HelpVerify