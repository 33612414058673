import {Modal} from "antd-mobile";
import React, {forwardRef, useImperativeHandle, useState} from "react";

/**
 * @author Yaochao
 * @date 2022/1/5 19:11
 * @description
 */
const ZkPopupModal = forwardRef((zprops, ref) => {
    const [visible, setVisible] = useState(false);
    const props = {visible: visible, animationType: 'slide-up', popup: true, ...zprops};
    props.visible = visible;
    let onClose = props.onClose;
    props.onClose = close;

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide
    }));

    //显示模态框
    function show() {
        setVisible(true)
    }

    //隐藏模态框
    function hide() {
        setVisible(false)
    }

    function close() {
        if (onClose) {
            onClose();
        } else {
            hide();
        }
    }

    return (
        <Modal {...props}>
        </Modal>
    )
});

export default ZkPopupModal