/**
 * @author Yaochao
 * @date 2021/12/21 18:56
 * @description
 */
import React from 'react'
import Util from "../../util/util";

class RankProgress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: 0
        }
    }

    componentDidMount() {
        this.setState({
            progress: this.props.progress
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!Util.isNull(nextProps.progress)) {
            this.setState({
                progress: nextProps.progress
            })
        }
    }

    render() {
        return (
            <span className='progress'>
                <span className='progress progressCur' style={{width: this.state.progress + '%'}}></span>
            </span>
        )
    }
}

export default RankProgress