import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import LiveCode from './pages/livecode/livecode'
import LiveCodeClose from './pages/livecode/middlePage/qunClose'
import FormMiddle from './pages/form/form'
import Complain from './pages/complain/complain'
import HelpVerify from './pages/helpverify/helpverify'
import FestivalSign from './pages/festivalsign/index'
import Logistics from './pages/logistics/index'
import FestivalSignPoster from './pages/festivalsign/poster'
import TaskPoster from './pages/taskrank/taskposter'
import TaskRank from './pages/taskrank/taskrank'
import MidPage from './pages/taskrank/midpage/midpage'
import Util from "./util/util";
import Api from "./util/Api";
import Loadable from "react-loadable";
import Loading from "./pages/loading";
import OAuthGuide from "./pages/oauth_guide";

class App extends Component {

    componentDidMount() {
        let dlogid = Util.getArgs("dlogid");
        if (dlogid) {
            Api.domainOpenLog(dlogid, function (data) {

            })
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={LiveCodeClose}/>
                    <Route path="/livecode" component={LiveCode}/>
                    <Route path="/msgclick/auth" component={
                        Loadable({
                            loader: () => import('./pages/msgclick/auth'),
                            loading: Loading
                        })
                    }/>
                    <Route path="/form" component={FormMiddle}/>
                    <Route path="/complain" component={Complain}/>
                    <Route path="/helpverify/:eventID" component={HelpVerify}/>
                    <Route path="/festivalsign/index/:eventID" component={FestivalSign}/>
                    <Route path="/festivalsign/poster/:eventID" component={FestivalSignPoster}/>
                    <Route path="/task/poster/:data" component={TaskPoster}/>
                    <Route path="/task/rank/:data" component={TaskRank}/>
                    <Route path="/task/midpage/:data" component={MidPage}/>
                    <Route path="/mshop/logistics" component={Logistics}/>
                    <Route path={"/oauth_guide"} component={OAuthGuide}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
