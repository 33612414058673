import React, {Component} from 'react';
import Loadable from 'react-loadable';
import {Route} from "react-router-dom";
import Loading from '../loading'

class Distri extends Component {
    render() {
        return (
            <div>
                <Route path={this.props.match.url + "/detail"} component={
                    Loadable({
                        loader: () => import('./middlePage/detail.js'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/auth"} component={
                    Loadable({
                        loader: () => import('./auth.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/empty"} component={
                    Loadable({
                        loader: () => import('./middlePage/empty.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/close"} component={
                    Loadable({
                        loader: () => import('./middlePage/group/qunClose.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/all"} component={
                    Loadable({
                        loader: () => import('./middlePage/group/qunAll.js'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/enterqun"} component={
                    Loadable({
                        loader: () => import('./middlePage/enterQun.js'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/tagslist"} component={
                    Loadable({
                        loader: () => import('./middlePage/group/tagsList.js'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/contant"} component={
                    Loadable({
                        loader: () => import('./middlePage/contant.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/customer/all"} component={
                    Loadable({
                        loader: () => import('./middlePage/qunAll.js'),
                        loading: Loading
                    })
                }/>
                <Route exact path={this.props.match.url + "/customer/close"} component={
                    Loadable({
                        loader: () => import('./middlePage/qunClose.js'),
                        loading: Loading
                    })
                }/>
            </div>
        );
    }
}

export default Distri;